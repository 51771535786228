<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="dnElevatorUsers">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('maintWorkOrder.status')">
          <el-select v-model="search.status" style="width: 100%" clearable :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
          {{ $t("common.add") }}
        </el-button>
      </template>
      <table-column prop="elevatorNo" :label="$t('elevator.no')" align="center" :width="150"></table-column>
      <table-column prop="elevatorName" show-overflow-tooltip :label="$t('elevator.name')"></table-column>
      <table-column prop="regNo" :label="$t('elevator.registerCode')"></table-column>
      <table-column prop="dtuCode" :label="$t('elevator.dtuCode')" align="center" :width="130"></table-column>
      <table-column prop="useUnitName" :label="$t('elevator.useUnit')" :width="200"></table-column>
      <table-column prop="userName" :label="$t('user.username')"></table-column>
      <table-column prop="name" :label="$t('user.name')"  align="center" :width="100"></table-column>
      <table-column prop="mobile" :label="$t('user.phone')" align="center"></table-column>
      <table-column prop="status" :label="$t('maintWorkOrder.status')" align="center" :width="70">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 1" type="success">
            {{$t("common.efficient")}}
          </el-tag>
          <el-tag v-if="scope.row.status === 0" type="warning">
            {{$t("deviceGateway.invalid")}}
          </el-tag>
        </template>
      </table-column>
      <table-column v-if="editAuth" :label="$t('common.operation')" align="center" :width="210">
        <template #default="scope">
          <el-button type="text" @click="$refs.editPage.open(scope.row.id)">
            {{ $t("elevatorNetApply.edit") }}
          </el-button>
          <el-button
            v-if="scope.row.status === 1"
            type="text"
            size="small"
            @click="changeState(scope.row,0)">
            {{$t("common.Disable")}}
          </el-button>
          <el-button
            v-if="scope.row.status === 0"
            type="text"
            size="small"
            @click="changeState(scope.row,1)">
            {{$t("common.Enable")}}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{ $t("common.delete")}}
          </el-button>
        </template>
      </table-column>
    </vm-table>
    <dn-elevator-user-edit ref="editPage" @save-success="getList(-1)"></dn-elevator-user-edit>
  </div>
</template>
<script>
  import DnElevatorUserEdit from "@/views/dnElevatorUser/DnElevatorUserEdit";

  export default {
    components: {DnElevatorUserEdit},
    data() {
      return {
        editAuth: this.$auth(844),
        loading: false,
        total: 0,
        search: {
          filter: "",
          status: "",
          startTime: "",
          endTime: "",
        },
        statusList: [
          { label: this.$t("common.efficient"), value: 1 },
          { label: this.$t("deviceGateway.invalid"), value: 0 },
        ]
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageIndex) {
        this.loading = false;
        this.$refs.vmTable.getList(pageIndex);
      },
      changeState(row, state) {
        let param = {
          id: row.id,
          status: state,
        };
        this.$http.post("dnElevatorUsers/updateState",param).then((res) => {
          this.getList(-1);
        });
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("dnElevatorUsers", row.id).then(res => {
            this.getList(-1);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch((error) => {
            if (error.response) {
              this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
            }
          });
        });
      },
      handleSearch(event) {
        this.filter = event;
        this.getList(1);
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      changePage(pageIndex) {
        this.getList(pageIndex);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
