<template>
  <el-dialog
    :title="$l('ota.operateRecord', '操作记录')"
    :visible.sync="dialogVisible"
    width="1300px"
    top="5vh"
    class="small-padding">
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="otaUserProgram/operateRecord">
      <template slot="adSearch">
        <vm-search :label="$l('ota.type', '类型')">
          <el-select v-model="search.type" style="width: 100%">
            <el-option :label="$l('ota.apply', '申请')" :value="1"></el-option>
            <el-option :label="$l('ota.burn', '烧录')" :value="2"></el-option>
          </el-select>
        </vm-search>
      </template>
      <table-column prop="username" :label="$l('ota.account', '账号')" align="center"></table-column>
      <table-column prop="name" :label="$l('ota.name', '姓名')" align="center"></table-column>
      <table-column prop="type" :label="$l('ota.type', '类型')" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.type === 1" type="success">{{$l('ota.apply', '申请')}}</el-tag>
          <el-tag v-if="scope.row.type === 2" type="danger">{{$l('ota.burn', '烧录')}}</el-tag>
        </template>
      </table-column>
      <table-column prop="programName" :label="$l('ota.program', '程序')" align="center"></table-column>
      <table-column prop="number" :label="$l('ota.count', '次数')" align="center"></table-column>
      <table-column prop="total" :label="$l('ota.balance', '余额')" align="center"></table-column>
      <table-column prop="operateTime" :label="$l('ota.operateTime', '操作时间')" align="center"></table-column>
    </vm-table>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        loading: false,
        dialogVisible: false,
        search: {
          type: "",
          userId:"",
          programId: "",
        },
      };
    },
    methods: {
      open(userId,programId) {
        this.dialogVisible = true;
        if (userId && programId) {
          this.search.userId = userId;
          this.search.programId = programId;
        }
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      onDialogClose() {
        this.dialogVisible = false;
      },
    },
  };
</script>

<style>
</style>
