<template>
  <el-dialog
    title="授权用户"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    top="5vh"
    @close="dialogReset">
    <div v-loading="contentLoading" class="body" style="min-height: 55vh;">
      <div>
        <el-button type="success" style=";margin: 0 0 0 5px" @click="$refs.userSelect.open()">
          {{$t("elevatorUser.selectUser")}}
        </el-button>
        <user-select
          ref="userSelect"
          :company-id="companyId"
          :multi-selection="true"
          @multi-select="onMultiUserSelect"
          @select="onUserSelect"></user-select>
        <div style="border: 1px solid #65676c;height: 50vh;overflow-y: scroll;margin: 5px 0 0 5px;padding: 3px;">
          <el-table
            :data="userList"
            border
            style="margin-top: 5px">
            <table-column prop="username" :label="$t('user.username')" align="center"></table-column>
            <table-column prop="name" :label="$t('user.name')" align="center"></table-column>
            <table-column prop="phone" :label="$t('user.phone')" align="center"></table-column>
            <table-column
              :label="$t('common.operation')"
              width="100"
              align="center"
              :tooltip="false"
              fixed="right">
                <template #default="scope">
                  <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                    {{ $t("common.delete") }}
                  </el-button>
                </template>
            </table-column>
          </el-table>
          <div style="clear:both"></div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import UserSelect from "@/views/user/UserSelect";
  import auth from "@/util/auth";

  export default {
    components: {UserSelect},
    data() {
      return {
        companyId:auth.getCurrentOrgId(),
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        userList: [],
        taskId: 0,
      };
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        if (id > 0) {
          this.taskId = id;
          this.getData(id);
        }
      },
      getData(id) {
        this.$http.get("otaTaskUser/user/" + id).then(res => {
          this.userList = res.data;
          if (!this.userList) {
            this.userList = [];
          }
        });
      },
      dialogReset() {
        this.userList = [];
      },
      onUserSelect(row) {
        this.userList.push(row);
      },
      onMultiUserSelect(rows) {
        console.log(rows);
        for (let row of rows) {
          let flag = true;
          for (let item of this.userList) {
            if (item.id === row.id) {
              flag = false;
            }
          }
          if (flag) {
            this.userList.push(row);
          }
        }
      },
      deleteRow(index) {
        this.userList.splice(index,1);
      },
      handleSubmit() {
        let userIds = [];
        if (this.userList.length === 0) {
          this.$message.error(this.$t("elevatorUser.userNotSelected"));
          return;
        }
        for (let item of this.userList) {
          userIds.push(item.id);
        }
        this.submitLoading = true;
        let params = {
          taskId: this.taskId,
          userIds: userIds,
        };
        this.$http.post("otaTaskUser/updateUserList", params).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
