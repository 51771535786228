<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="otaProgram">
      <template slot="toolbar">
        <el-button v-if="adminAuth" :loading="refreshLoading" @click="refresh">
          {{$l("tpLink.synchronization", "同步")}}
        </el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('ota.isEffective', '是否锁定')">
          <el-select v-model="search.isActive" style="width: 100%">
            <el-option :label="$l('ota.locking', '锁定')" :value="false"></el-option>
            <el-option :label="$l('ota.effective', '有效')" :value="true"></el-option>
          </el-select>
        </vm-search>
      </template>
      <table-column
        prop="program"
        :label="$l('ota.program', '程序名')"
        align="center"></table-column>
      <table-column
        prop="programType"
        :label="$l('ota.count', '程序类型')"
        align="center"></table-column>
      <table-column
        prop="softCode"
        :label="$l('ota.program', '软件编码')"
        align="center"></table-column>
      <table-column
        prop="version"
        :label="$l('ota.program', '版本')"
        align="center"></table-column>
      <table-column
        prop="softNote"
        :label="$l('ota.program', '软件描述')"></table-column>
      <table-column
        prop="useNote"
        :label="$l('ota.program', '用途描述')"></table-column>
      <table-column prop="isActive" :label="$l('ota.isEffective', '是否锁定')" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.isActive === false" type="info">
            {{$l('ota.locking', '锁定')}}
          </el-tag>
          <el-tag v-if="scope.row.isActive === true" type="success">
            {{$l('ota.effective', '有效')}}
          </el-tag>
        </template>
      </table-column>
      <table-column prop="createTime" :label="$l('msgPushRecord.createTime', '生成时间')" align="center"></table-column>
      <table-column prop="updateTime" :label="$l('ota.updateTime', '更新时间')" align="center"></table-column>
      <table-column prop="orgName" :label="$l('ota.ownedCompany', '所属企业')" align="center"></table-column>
      <table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" :width="150" fixed="right" :tooltip="false">
        <template #default="scope">
<!--          <el-button v-if="adminAuth" type="text" @click="$refs.updateProgram.open(scope.row.id,1)">{{$l('ota.applyNumber', '充值')}}</el-button>-->
<!--          <el-button v-if="adminAuth" type="text" @click="$refs.updateProgram.open(scope.row.id,2)">{{$l('ota.applyNumber', '扣减')}}</el-button>-->
<!--          <el-button v-if="adminAuth" type="text" @click="$refs.record.open(scope.row.id)">{{$l('ota.applyNumber', '流水记录')}}</el-button>-->
          <el-button type="text" @click="$refs.applyProgram.open(scope.row.id)">{{$l('ota.applyNumber', '申请次数')}}</el-button>
        </template>
      </table-column>
    </vm-table>
    <ota-update-number ref="updateProgram" @save-success="getList(-1)"></ota-update-number>
    <ota-apply-program ref="applyProgram"></ota-apply-program>
    <ota-record-list ref="record"></ota-record-list>
  </div>
</template>
<script>
  import OtaApplyProgram from "@/views/elevator/elevatorSetting/DIAoOTA/otaApply/OtaApplyProgram";
  import auth from "@/util/auth";
  import OtaUpdateNumber from "@/views/elevator/elevatorSetting/DIAoOTA/OtaUpdateNumber";
  import OtaRecordList from "@/views/elevator/elevatorSetting/DIAoOTA/otaOperate/OtaRecordList";

  export default {
    components: {OtaApplyProgram,OtaUpdateNumber,OtaRecordList},
    data() {
      return {
        adminAuth: auth.getUsername() === "admin" || auth.getUsername() === "superAdmin" || auth.getUsername() === "Ota_ESD",
        refreshLoading: false,
        loading: true,
        search: {
          filter: "",
          isActive: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      refresh() {
        this.refreshLoading = true;
        this.$http.post("otaProgram/getProgram").then(res => {
          this.refreshLoading = false;
          this.getList(1);
          this.$message.success(this.$l("tpLink.synchronizationSucceeded", "同步成功"));
        }).catch(err => {
          this.refreshLoading = false;
          this.$message.error(this.$l("tpLink.synchronizationFailed", "同步失败"));
        });
      },
    },
  };
</script>
