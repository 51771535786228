<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="otaApply">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('ota.approvalStatus','审批状态')">
          <el-select v-model="search.applyState" style="width: 100%">
            <el-option :label="$l('ota.applying','申请中')" :value="1"></el-option>
            <el-option :label="$l('ota.approved','已同意')" :value="2"></el-option>
            <el-option :label="$l('ota.rejected','已拒绝')" :value="3"></el-option>
          </el-select>
        </vm-search>
      </template>
      <table-column prop="username" :label="$l('ota.applicantAccount','申请人账号')" align="center"></table-column>
      <table-column prop="name" :label="$l('ota.applicantName','申请人姓名')" align="center"></table-column>
      <table-column prop="programName" :label="$l('ota.applyProgram','申请程序')" align="center"></table-column>
      <table-column prop="applyNumber" :label="$l('ota.applyNumber','申请次数')" align="center"></table-column>
      <table-column prop="applyState" :label="$l('ota.approvalStatus','审批状态')" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.applyState === 1" type="info">{{$l('ota.applying','申请中')}}</el-tag>
          <el-tag v-if="scope.row.applyState === 2" type="success">{{$l('ota.approved','已同意')}}</el-tag>
          <el-tag v-if="scope.row.applyState === 3" type="danger">{{$l('ota.rejected','已拒绝')}}</el-tag>
        </template>
      </table-column>
      <table-column prop="applyTime" :label="$l('ota.applyTime','申请时间')" align="center"></table-column>
      <table-column prop="acceptAccount" :label="$l('ota.approverAccount','审批人账号')" align="center"></table-column>
      <table-column prop="acceptName" :label="$l('ota.approverName','审批人姓名')" align="center"></table-column>
      <table-column prop="finishTime" :label="$l('ota.endTime','结束时间')" align="center"></table-column>
      <table-column v-if="adminAuth" :label="$l('elevatorNetApply.operate', '操作')" align="center" :width="90" fixed="right">
        <template #default="scope">
          <el-button type="text" @click="$refs.detail.open(scope.row)">{{$l('ota.details','明细')}}</el-button>
        </template>
      </table-column>
    </vm-table>
    <ota-apply-detail ref="detail" @save-success="getList(-1)"></ota-apply-detail>
  </div>
</template>
<script>
  import OtaApplyDetail from "@/views/elevator/elevatorSetting/DIAoOTA/otaApply/OtaApplyDetail";
  import auth from "@/util/auth";

  export default {
    components: {OtaApplyDetail},
    data() {
      return {
        adminAuth: auth.getUserType() === 0 || auth.getUserType() === 1,
        refreshLoading: false,
        loading: true,
        search: {
          filter: "",
          applyState: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
    },
  };
</script>
