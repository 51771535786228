<template>
  <div class="device-list">
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="ota/task">
      <template slot="toolbar">
        <el-button :loading="refreshLoading" @click="$refs.taskEdit.open()">
          {{$l("tpLink.synchronization", "新建")}}
        </el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('ota.isEffective', '状态')">
          <el-select v-model="search.status" :placeholder="$t('common.pleaseSelect')" clearable>
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
      </template>
      <el-table-column type="expand">
        <template slot-scope="scope">
          <table class="childTable">
            <tr>
              <th>程序名</th>
              <th>程序类型</th>
              <th>软件编码</th>
              <th>版本</th>
              <th style="text-align: center">已烧录/总次数</th>
              <th style="text-align: center">操作</th>
            </tr>
            <tr v-for="(item, index) in scope.row.otaTaskDetailVoList" :key="index">
              <td>{{item.programName}}</td>
              <td>{{item.programType}}</td>
              <td>{{item.softCode}}</td>
              <td>{{item.version}}</td>
              <td style="text-align: center">{{item.useNumber}}/{{item.totalNumber}}</td>
              <td style="text-align: center">
                <el-button type="text" @click="$refs.setNumber.open(item.id)">编辑</el-button>
              </td>
            </tr>
          </table>
        </template>
      </el-table-column>
      <table-column
        prop="taskName"
        :label="$l('ota.program', '任务名称')"
        width="150px"
        align="center"></table-column>
      <table-column
        prop="taskNote"
        :label="$l('ota.count', '任务描述')"
        align="center"></table-column>

      <table-column prop="status" :label="$l('ota.isEffective', ' 已烧录/总次数')" width="150px" align="center">
        <template #default="scope">
          {{scope.row.useNumber}}/{{scope.row.totalNumber}}
        </template>
      </table-column>
      <table-column prop="status" :label="$l('ota.isEffective', '状态')" width="150px" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 1" type="info">
            {{$l("ota.locking", "进行中")}}
          </el-tag>
          <el-tag v-if="scope.row.status === 2" type="success">
            {{$l("ota.effective", "已完成")}}
          </el-tag>
          <el-tag v-if="scope.row.status === 3" type="danger">
            {{$l("ota.effective", "已停止")}}
          </el-tag>
        </template>
      </table-column>
      <table-column prop="createTime" :label="$l('msgPushRecord.createTime', '生成时间')"
                       align="center"></table-column>
      <table-column prop="updateTime" :label="$l('ota.updateTime', '更新时间')" align="center"></table-column>
      <table-column prop="orgName" :label="$l('ota.ownedCompany', '所属企业')" align="center"></table-column>
      <table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" :width="150" fixed="right" :tooltip="false">
        <template #default="scope">
          <el-button v-if="adminAuth" type="text" @click="$refs.taskEdit.open(scope.row.id)">
            {{$l("common.edit", "编辑")}}
          </el-button>
          <el-button type="text" @click="$refs.taskUser.open(scope.row.id)">
            {{$l("ota.applyNumber", "用户列表")}}
          </el-button>
          <!--          <el-button v-if="adminAuth" type="text" @click="$refs.updateProgram.open(scope.row.id,1)">{{$l('ota.applyNumber', '充值')}}</el-button>-->
          <!--          <el-button v-if="adminAuth" type="text" @click="$refs.updateProgram.open(scope.row.id,2)">{{$l('ota.applyNumber', '扣减')}}</el-button>-->
          <!--          <el-button v-if="adminAuth" type="text" @click="$refs.record.open(scope.row.id)">{{$l('ota.applyNumber', '流水记录')}}</el-button>-->
          <!--          <el-button type="text" @click="$refs.applyProgram.open(scope.row.id)">{{$l('ota.applyNumber', '申请次数')}}</el-button>-->
        </template>
      </table-column>
    </vm-table>
    <ota-task-user-list ref="taskUser"></ota-task-user-list>
    <ota-set-number ref="setNumber" @save-success="getList(-1)"></ota-set-number>
    <ota-update-number ref="updateProgram" @save-success="getList(-1)"></ota-update-number>
    <ota-apply-program ref="applyProgram"></ota-apply-program>
    <ota-record-list ref="record"></ota-record-list>
    <ota-task-edit ref="taskEdit" @save-success="getList(-1)"></ota-task-edit>
  </div>
</template>
<script>
  import OtaApplyProgram from "@/views/elevator/elevatorSetting/DIAoOTA/otaApply/OtaApplyProgram";
  import auth from "@/util/auth";
  import OtaUpdateNumber from "@/views/elevator/elevatorSetting/DIAoOTA/OtaUpdateNumber";
  import OtaRecordList from "@/views/elevator/elevatorSetting/DIAoOTA/otaOperate/OtaRecordList";
  import OtaTaskEdit from "@/views/elevator/elevatorSetting/DIAoOTA/OtaTaskEdit";
import OtaSetNumber from "@/views/elevator/elevatorSetting/DIAoOTA/OtaSetNumber";
import OtaTaskUserList from "@/views/elevator/elevatorSetting/DIAoOTA/OtaTaskUserList";

  export default {
    components: {OtaTaskUserList,OtaSetNumber,OtaTaskEdit, OtaApplyProgram, OtaUpdateNumber, OtaRecordList},
    data() {
      return {
        adminAuth: auth.getUsername() === "admin" || auth.getUsername() === "superAdmin" || auth.getUsername() === "Ota_ESD",
        refreshLoading: false,
        loading: true,
        search: {
          filter: "",
          isActive: "",
        },
        statusOptions: [
          {label: "进行中", value: 1},
          {label: "已完成", value: 2},
          {label: "已停止", value: 3},
        ],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      create() {

      },
    },
  };
</script>

<style lang="scss" scoped>
.device-list {
  ::v-deep .el-table__expanded-cell {
    background-color: #F1F5F8;
    padding: 15px 25px;

    &:hover {
      background-color: #F1F5F8 !important;
    }
  }

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: white;
  }

  .childTable {
    width: 100%;
    border-collapse: collapse;

    td {
      border: 1px solid #D8DCE5;
      padding: 5px 10px;
      background-color: #FAFAFA;
    }

    th {
      border: 1px solid #D8DCE5;
      padding: 8px 10px;
      background-color: #EEF2F7;
    }
  }
}
</style>
