<template>
  <el-dialog
    :title="$t('elevator.callElevator')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    top="5vh"
    @close="close">
    <div v-loading="contentLoading" class="body" style="min-height: 55vh;">
      <div class="vm-separate">
        <div>
          <el-button type="primary" @click="$refs.elevatorSelect.open()">
            {{$t("elevatorUser.selectLift")}}
          </el-button>
          <elevator-select
            ref="elevatorSelect"
            :multi-selection="true"
            @select="onElevatorSelect"
            @multi-select="onMultiElevatorSelect"></elevator-select>
          <div style="border: 1px solid #65676c;height: 50vh;overflow-y: scroll;margin: 5px 5px 0 0;padding: 3px;">
            <el-table
              ref="multipleTable"
              :data="elevatorList"
              style="width: 100%;margin-top: 5px"
              border>
              <table-column prop="no" :label="$t('elevator.no')"></table-column>
              <table-column prop="name" :label="$t('elevator.name')"></table-column>
              <table-column prop="regNo" :label="$t('elevator.registerCode')"></table-column>
              <table-column prop="dtuCode" :label="$t('elevator.dtuCode')" align="center" :width="130"></table-column>
            </el-table>
          </div>
        </div>
        <div>
          <el-button type="success" style=";margin: 0 0 0 5px" @click="$refs.userSelect.open()">
            {{$t("elevatorUser.selectUser")}}
          </el-button>
          <user-select
            ref="userSelect"
            :multi-selection="true"
            @multi-select="onMultiUserSelect"
            @select="onUserSelect"></user-select>
          <div style="border: 1px solid #65676c;height: 50vh;overflow-y: scroll;margin: 5px 0 0 5px;padding: 3px;">
            <el-table
              :data="userList"
              border
              style="margin-top: 5px">
              <table-column prop="username" :label="$t('user.username')" align="center"></table-column>
              <table-column prop="name" :label="$t('user.name')" align="center"></table-column>
              <table-column prop="phone" :label="$t('user.phone')" align="center"></table-column>
            </el-table>
            <div style="clear:both"></div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import ElevatorSelect from "../elevator/ElevatorSelect.vue";
  import UserSelect from "../user/UserSelect.vue";

  export default {
    components: {ElevatorSelect, UserSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        elevatorList: [],
        userList: [],
        multiSelect: [],
      };
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        if (id > 0) {
          this.getData(id);
        }
      },
      getData(id) {
        this.$http.get("dnElevatorUsers/getById/" + id).then(res => {
          this.elevatorList = res.data.elevatorList;
          this.userList = res.data.userList;
        });
      },
      close() {
        this.dialogVisible = false;
        this.userList = [];
        this.elevatorList = [];
      },
      onElevatorSelect(row) {
        this.elevatorList = [];
        this.elevatorList.push(row);
      },
      onMultiElevatorSelect(rows) {
        for (let row of rows) {
          let flag = true;
          for (let item of this.elevatorList) {
            if (item.id === row.id) {
              flag = false;
            }
          }
          if (flag) {
            this.elevatorList.push(row);
          }
        }
      },
      onUserSelect(row) {
        this.userList = [];
        this.userList.push(row);
      },
      onMultiUserSelect(rows) {
        for (let row of rows) {
          let flag = true;
          for (let item of this.userList) {
            if (item.id === row.id) {
              flag = false;
            }
          }
          if (flag) {
            this.userList.push(row);
          }
        }
      },
      handleSubmit() {
        let userIds = [];
        let elevatorIds = [];
        if (this.elevatorList.length === 0) {
          this.$message.error(this.$t("elevatorUser.elevatorNotSelected"));
          return;
        }
        if (this.userList.length === 0) {
          this.$message.error(this.$t("elevatorUser.userNotSelected"));
          return;
        }
        for (let item of this.userList) {
          userIds.push(item.id);
        }
        for (let item of this.elevatorList) {
          elevatorIds.push(item.id);
        }
        this.submitLoading = true;
        let params = {
          elevatorIds: elevatorIds,
          userIds: userIds,
        };
        this.$http.post("dnElevatorUsers", params).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
